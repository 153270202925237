<template>
  <card-component title="Processos" class="has-table has-mobile-sort-spaced mt-5">
    <b-field class="m-3">
      <b-select v-model="pagination.perPage" @input="perPageChange">
        <option :value="15">15 por página</option>
        <option :value="25">25 por página</option>
        <option :value="50">50 por página</option>
      </b-select>
    </b-field>
    <b-table
      :striped="true"
      :hoverable="true"
      :loading="isLoading"
      :data="listaProcessos"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
      :backend-pagination="true"
      :total="pagination.total"
      :paginated="true"
      @page-change="getProcessos"
    >
      <b-table-column label="Referencia" field="Referencia" v-slot="props">
        {{ props.row.Referencia }}
      </b-table-column>
      <b-table-column label="Status" field="Status" v-slot="props">
        <b-tag v-if="parseStatus(props.row.Status) === 'Ativo'" type="is-success">{{parseStatus(props.row.Status)}}</b-tag>
        <b-tag v-else type="is-info">{{parseStatus(props.row.Status)}}</b-tag>
      </b-table-column>
      <b-table-column label="Data de criação" field="DTCreated" v-slot="props">
        {{ formatDate(props.row.DTCreated) }}
      </b-table-column>
      <b-table-column custom-key="actions" cell-class="is-actions-cell" v-slot="props">
        <div class="buttons is-right">
          <router-link :to="{ name:'processo.detalhes', params: {id: props.row.IDProcesso} }" class="button is-small is-primary">
            <b-icon icon="arrow-right" size="is-small"/>
          </router-link>
        </div>
      </b-table-column>
    </b-table>
  </card-component>
</template>
<script>
import CardComponent from '@/components/CardComponent'
import {
  getProcessosByClient,
  formatPaginationObj,
  formatDate,
  parseStatus,
  notificationError
} from '@/core'

export default {
  name: 'ProcessoByCliente',
  components: {
    CardComponent
  },
  props: ['idCliente'],
  data () {
    return {
      isLoading: true,
      listaProcessos: [],
      pagination: formatPaginationObj()
    }
  },
  methods: {
    formatDate (val) { return formatDate(val) },
    parseStatus: (statusCode) => parseStatus(statusCode),
    perPageChange () { this.getProcessos() },
    getProcessos (currentPage = this.pagination.currentPage, perPage = this.pagination.perPage) {
      this.isLoading = true
      getProcessosByClient(this.idCliente, currentPage, perPage)
        .then(res => {
          this.pagination = formatPaginationObj(res)
          this.listaProcessos = res.dados
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
          notificationError(this.$buefy.notification)
        })
    }
  },
  created () {
    this.getProcessos()
  }
}
</script>
