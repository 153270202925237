<template>
  <div>
    <hero-bar>
      {{ heroTitle }}
      <router-link :to="{name: 'cliente.produtos'}" slot="right" class="button is-info" >
        Ver produtos
      </router-link>
    </hero-bar>

    <section class="section is-main-section">
      <b-breadcrumb align="is-left">
        <b-breadcrumb-item tag='router-link' to="/">Home</b-breadcrumb-item>
        <b-breadcrumb-item tag='router-link' to="/clientes">Clientes</b-breadcrumb-item>
        <b-breadcrumb-item tag='router-link' :to="`/clientes/${$route.params.id}`" active>Detalhe cliente</b-breadcrumb-item>
      </b-breadcrumb>

        <card-component
          title="Detalhes do Cliente"
          icon="account"
          class="tile is-child"
          v-if="clienteDetalhes"
        >

          <div class="columns">
            <div class="column">
              <div class="columns">
                <div class="column">
                  <b-field label="Nome">
                    <b-input :value="clienteDetalhes.Name" custom-class="is-static" readonly />
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="E-mail">
                    <p>{{ clienteDetalhes.Email }}</p>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Telefone">
                    <p>{{ clienteDetalhes.NumberPhone }}</p>
                  </b-field>
                </div>
              </div>

            </div>
          </div>

          <div class="columns">
            <div class="column">
              <b-button class="mr-2" @click="isComponentModalActive = true">Editar cliente</b-button>
              <b-button type="is-danger" @click="confirmDelete({ id: clienteDetalhes.IDCliente, name: clienteDetalhes.Name })">Deletar cliente</b-button>
            </div>
          </div>
        </card-component>

        <CNPJ
          v-if="clienteDetalhes.ListaCNPJs"
          :data="clienteDetalhes.ListaCNPJs"
          :idCliente="$route.params.id"
          @updateCliente="getDetalhesCliente"
        />

        <ProcessoByCliente :idCliente="$route.params.id" />
    </section>

    <b-modal
        v-model="isComponentModalActive"
        has-modal-card
        @close="reset"
    >
      <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Editar Cliente</p>
        </header>
        <section class="modal-card-body">
          <form @submit.prevent="editCliente">
            <b-field label="Nome" horizontal>
              <b-field>
                <b-input
                  v-model="form.Name"
                  placeholder="Name"
                  name="name"
                  required
                />
              </b-field>
            </b-field>
            <b-field label="Email" horizontal>
              <b-field>
                <b-input
                  v-model="form.Email"
                  placeholder="Email"
                  name="email"
                  type="email"
                  required
                />
              </b-field>
            </b-field>
            <b-field label="Telefone" horizontal>
              <the-mask
                v-model="form.NumberPhone"
                class="input"
                :mask="['(##) ####-####', '(##) #####-####']"
                placeholder="telefone"
              />
            </b-field>
          </form>
        </section>
        <footer class="modal-card-foot">
          <b-button
            label="Cancelar"
            @click="isComponentModalActive = false"
          />
          <b-button
            label="Editar cliente"
            type="is-primary"
            @click="editCliente"
            :loading="isLoadingForm"
          />
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import HeroBar from '@/components/HeroBar'
import CardComponent from '@/components/CardComponent'
import CNPJ from './CNPJ.vue'
import ProcessoByCliente from './components/ProcessoByCliente'
import {
  deleteCliente,
  getCliente,
  editaCliente,
  resetFields,
  equalsIgnoreOrder,
  notificationError
} from '@/core'

export default {
  name: 'DetalheCliente',
  components: {
    CardComponent,
    HeroBar,
    CNPJ,
    ProcessoByCliente
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      clienteDetalhes: {},
      validKeys: [
        'Descricao_PTBR',
        'Descricao_EN',
        'Descricao_NF',
        'Codigo_Produto',
        'NCM',
        'Linha_Catalago',
        'Unidade_Medida_Comercializada',
        'Cod_Unid_Peso',
        'Necessita_LI'
      ],
      form: {
        Name: null,
        Email: null,
        NumberPhone: null
      },
      isComponentModalActive: false,
      isLoadingForm: false
    }
  },
  computed: {
    heroTitle () {
      if (this.clienteDetalhes && this.nomeCliente) {
        return 'Cliente: ' + this.nomeCliente
      } else {
        return 'Detalhe cliente'
      }
    },
    nomeCliente () {
      return this.clienteDetalhes.Name
    }
  },
  methods: {
    getDetalhesCliente () {
      const id = this.$route.params.id
      getCliente(id)
        .then(res => {
          this.clienteDetalhes = res.data
          this.form = {
            Name: res.Name,
            NumberPhone: res.NumberPhone,
            IDCliente: res.IDCliente,
            Email: res.Email
          }
        })
        .catch(() => {
          this.clienteDetalhes = {}
          notificationError(this.$buefy.notification)
        })
    },
    confirmDelete (client) {
      this.$buefy.dialog.confirm({
        title: 'Deletar cliente',
        message: `Tem certeza que deseja deletar o cliente ${client.name}? Essa ação não pode ser desfeita.`,
        confirmText: 'Deletar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteCliente(client)
      })
    },
    deleteCliente (client) {
      this.isLoading = true
      deleteCliente(client.id)
        .then(() => {
          this.$buefy.notification.open({
            duration: 5000,
            message: 'Cliente removido com sucesso.',
            position: 'is-bottom-right',
            type: 'is-success',
            hasIcon: true
          })
          this.$router.push({ name: 'clientes' })
        })
        .catch(() => {
          this.isLoading = false
          notificationError(this.$buefy.notification)
        })
    },
    keysIsValid (rows) { return equalsIgnoreOrder(rows[0], this.validKeys) },
    editCliente () {
      this.isLoadingForm = true
      editaCliente(this.form)
        .then(res => {
          this.isLoadingForm = false
          this.isComponentModalActive = false
          this.getDetalhesCliente()
        })
        .catch(() => {
          this.isLoadingForm = false
          notificationError(this.$buefy.notification)
        })
    },
    reset () {
      this.form = resetFields(this.form)
    }
  },
  mounted () {
    this.getDetalhesCliente()
  }
}
</script>
