<template>
  <div>
    <card-component title="CNPJs" class="has-table has-mobile-sort-spaced mt-5">
      <div class="p-2">
        <b-button type="is-primary" @click="isComponentModalActive = true">Adicionar CNPJ</b-button>
      </div>
      <b-table
        :striped="true"
        :hoverable="true"
        :data="data"
      >

        <b-table-column label="Descrição" field="Descricao" v-slot="props">
          {{ props.row.Descricao}}
        </b-table-column>
        <b-table-column label="CNPJ" field="CNPJ" v-slot="props">
          {{ formatCNPJ(props.row.CNPJ) }}
        </b-table-column>
        <b-table-column custom-key="actions" cell-class="is-actions-cell" v-slot="props">
            <div class="buttons is-right">
              <b-button @click="confirmDelete({ id: props.row.IDCNPJ, cnpj: props.row.CNPJ })" class="button is-small is-danger">
                <b-icon icon="delete" size="is-small"/>
              </b-button>
            </div>
        </b-table-column>
        <template #empty>
          <p class="has-text-centered">Não há dados para exibir</p>
        </template>
      </b-table>
    </card-component>

    <b-modal v-model="isComponentModalActive" has-modal-card @close="reset">
      <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Cadastrar CNPJ</p>
        </header>
        <section class="modal-card-body">
          <form @submit.prevent="submit">
            <b-field label="CNPJ" horizontal>
              <the-mask class="input" placeholder="CNPJ" v-model="form.CNPJ" :mask="['##.###.###/####-##']" required/>
            </b-field>
            <b-field label="Descrição" horizontal>
              <b-input v-model="form.Descricao" placeholder="Descrição" required />
            </b-field>
          </form>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-primary is-outlined" @click="reset">Reset</b-button>
            <b-button label="Cadastrar" type="is-primary" @click="submit" :loading="isLoadingForm"/>
        </footer>
    </div>
  </b-modal>
  </div>
</template>
<script>
import { formatCNPJ, resetFields, cadastroCNPJ, notificationError, deleteCNPJ } from '@/core'
import CardComponent from '@/components/CardComponent'

export default {
  name: 'CNPJ',
  components: {
    CardComponent
  },
  data () {
    return {
      isComponentModalActive: false,
      isLoadingForm: false,
      form: {
        CNPJ: null,
        Descricao: null,
        IDCliente: this.idCliente
      }
    }
  },
  props: ['data', 'idCliente'],
  methods: {
    formatCNPJ (val) { return formatCNPJ(val) },
    isEmpty () {
      return (this.form.CNPJ === null || this.form.Descricao === null) || (this.form.CNPJ.length === 0 || this.form.Descricao.length === 0)
    },
    reset () {
      this.form = resetFields(this.form)
      this.form.IDCliente = this.idCliente
    },
    submit () {
      this.isLoadingForm = true
      if (this.isEmpty()) {
        notificationError(this.$buefy.notification, 'Campos não preenchidos')
        this.isLoadingForm = false
      } else {
        cadastroCNPJ(this.form)
          .then(res => {
            this.$buefy.notification.open({
              message: 'CNPJ cadastrado com sucesso!',
              type: 'is-success'
            })
            this.isLoadingForm = false
            this.isComponentModalActive = false
            this.reset()
            this.$emit('updateCliente')
          })
          .catch(() => {
            this.isLoadingForm = false
            notificationError(this.$buefy.notification)
          })
      }
    },
    confirmDelete (cnpj) {
      this.$buefy.dialog.confirm({
        title: 'Deletar CNPJ',
        message: `Tem certeza que deseja deletar ${formatCNPJ(cnpj.cnpj)}? Essa ação não pode ser desfeita.`,
        confirmText: 'Deletar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteCNPJ(cnpj.id)
      })
    },
    deleteCNPJ (id) {
      deleteCNPJ(id)
        .then(res => {
          this.$buefy.notification.open({
            message: 'CNPJ removido com sucesso!',
            type: 'is-success'
          })
          this.$emit('updateCliente')
        })
        .catch(() => notificationError(this.$buefy.notification))
    }
  }
}
</script>
